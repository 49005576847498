import defaultMaleAvatar from "../assets/models/DefaultAvatar.glb";
import defaultFemaleAvatar from "../assets/models/DefaultAvatar.glb";
import configs from "./configs";

const urlParams = new URLSearchParams(location.search);

const USER_INFO = {
  name: urlParams.get("name") ?? "User",
  gender: urlParams.get("gender") ?? "female",
  avatarId: urlParams.get("avatarId") ?? "1234"
};

const S3_BUCKET = configs.translation("avatar-s3-bucket");
const AVATAR_BASE_URL = `${S3_BUCKET}`;

export const generateRandomName = () => `${USER_INFO.name}-${Math.floor(10000 + Math.random() * 10000)}`;

export const fetchRandomDefaultAvatarId = async () => {
  console.log(S3_BUCKET);

  if (USER_INFO.avatarId === "1234" || !USER_INFO.avatarId) {
    return USER_INFO.gender === "female"
      ? new URL(defaultFemaleAvatar, location.href).href
      : new URL(defaultMaleAvatar, location.href).href;
  }

  return `${AVATAR_BASE_URL}${USER_INFO.avatarId}.glb`;
};
